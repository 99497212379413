import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Flex, Box, Icon, Label, NakedButton, Text } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

const OccupantPickerButton = styled(NakedButton)`
  touch-action: manipulation;
`;

const Ages = styled.span`
  font-weight: ${themeGet('fontWeights.normal')};
  margin-left: 7px;
`;

const Occupant = ({ label, ages, count, increment, decrement, min, max, ...rest }) => (
  <Box p={4} {...rest}>
    <Label>
      <Text fontSize={['base', 'xs', 'xs']} fontWeight="bold">
        {label}
        {ages && <Ages data-testid="occupant-ages">({ages})</Ages>}
      </Text>
    </Label>
    <Flex alignItems="center" bg="white">
      {count > min ? (
        <OccupantPickerButton flex="1 1 auto" p={3} onClick={decrement} data-testid="decrement">
          <Icon name="remove" />
        </OccupantPickerButton>
      ) : (
        <Box textAlign="center" p={3}>
          <Icon name="remove" color="greys.alto" />
        </Box>
      )}
      <Flex flex="3" justifyContent="center" data-testid="occupant-count">
        {count}
      </Flex>
      {count < max ? (
        <OccupantPickerButton flex="1 1 auto" p={3} onClick={increment} data-testid="increment">
          <Icon name="add" />
        </OccupantPickerButton>
      ) : (
        <Box textAlign="center" p={3}>
          <Icon name="add" color="greys.alto" />
        </Box>
      )}
    </Flex>
  </Box>
);

Occupant.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  ages: PropTypes.string,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

Occupant.defaultProps = {
  ages: undefined,
};

export default Occupant;
