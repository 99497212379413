import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { Box, Flex, Hide } from '@qga/roo-ui/components';
import ResponsiveModal from 'components/ResponsiveModal';
import Dropdown from 'components/Dropdown';
import Occupant from './Occupant';
import { mediaQuery } from 'lib/styledSystem';
import TextButton from 'components/TextButton';
import MultiRoomMessage from './MultiRoomMessage/MultiRoomMessage';
import OccupantDisplay from './OccupantDisplay';

const MAX_OCCUPANT_COUNT = 9;
const MULTI_ROOM_MESSAGE_PROMPT_THRESHOLD = 3;

const Root = styled(Box)`
  position: relative;
  user-select: none;
`;

const Actions = styled(Box)`
  position: fixed;
  padding: ${themeGet('space.4')};
  text-align: right;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;

  ${mediaQuery.minWidth.sm} {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    border: 1px solid ${themeGet('colors.greys.alto')};
  }
`;

const OccupantPicker = ({ occupants, labelOptions, updateQuery, viewThreshold, verboseInMobile, multiroomEnabled }) => {
  const [adults, setAdults] = useState(occupants.adults ?? 2);
  const [children, setChildren] = useState(occupants.children ?? 0);
  const [infants, setInfants] = useState(occupants.infants ?? 0);
  const occupantCount = adults + children + infants;

  const isDirty = adults !== occupants.adults || children !== occupants.children || infants !== occupants.infants;
  const showMultiRoomMessage = multiroomEnabled && adults > MULTI_ROOM_MESSAGE_PROMPT_THRESHOLD;

  const updateGuests = useCallback(() => {
    setAdults(occupants.adults ?? 2);
    setChildren(occupants.children ?? 0);
    setInfants(occupants.infants ?? 0);
  }, [occupants.adults, occupants.children, occupants.infants]);

  const apply = () => {
    if (isDirty) {
      updateQuery({ adults, children, infants });
    }
  };

  const handleCancel = () => {
    if (isDirty) {
      updateGuests();
    }
  };

  useEffect(() => {
    updateGuests();
  }, [occupants, updateGuests]);

  const incrementAdults = useCallback(() => setAdults(adults + 1), [adults]);
  const decrementAdults = useCallback(() => setAdults(adults - 1), [adults]);

  const incrementChildren = useCallback(() => setChildren(children + 1), [children]);
  const decrementChildren = useCallback(() => setChildren(children - 1), [children]);

  const incrementInfants = useCallback(() => setInfants(infants + 1), [infants]);
  const decrementInfants = useCallback(() => setInfants(infants - 1), [infants]);

  return (
    <ResponsiveModal title="Select guests" onSubmit={apply} onBlur={apply} onCancel={handleCancel}>
      {({ isOpen, openModal, submitModal, cancelModal }) => (
        <>
          <Root data-testid="occupant-picker">
            {!isOpen && (
              <OccupantDisplay
                display={['block', 'none', 'none']}
                labelOptions={labelOptions}
                verboseInMobile={verboseInMobile}
                openModal={openModal}
                occupantCount={occupantCount}
                isOpen={isOpen}
              />
            )}
            <OccupantDisplay
              display={['none', 'block', 'block']}
              labelOptions={labelOptions}
              verboseInMobile={verboseInMobile}
              openModal={openModal}
              occupantCount={occupantCount}
              isOpen={isOpen}
            />
            {isOpen && (
              <Dropdown
                anchorX="right"
                width={['100%', '194px', '388px']}
                viewThreshold={viewThreshold}
                skipToContent="#main-content"
                cancelModal={cancelModal}
              >
                <Flex flexWrap="wrap">
                  <Box width={[1, 1, 0.5]}>
                    <Occupant
                      data-testid="occupant-picker-adults"
                      label="Adults"
                      count={adults}
                      increment={incrementAdults}
                      decrement={decrementAdults}
                      min={1}
                      max={MAX_OCCUPANT_COUNT}
                    />
                  </Box>
                  <Box width={[1, 1, 0.5]}>
                    <Occupant
                      data-testid="occupant-picker-children"
                      label="Children"
                      ages="2 - 12yrs"
                      count={children}
                      increment={incrementChildren}
                      decrement={decrementChildren}
                      min={0}
                      max={MAX_OCCUPANT_COUNT}
                    />
                  </Box>
                  <Box width={[1, 1, 0.5]}>
                    <Occupant
                      data-testid="occupant-picker-infants"
                      label="Infants"
                      ages="< 2yrs"
                      count={infants}
                      increment={incrementInfants}
                      decrement={decrementInfants}
                      min={0}
                      max={MAX_OCCUPANT_COUNT}
                    />
                  </Box>
                </Flex>

                {showMultiRoomMessage && <MultiRoomMessage />}

                <Actions>
                  <Hide xs>
                    <TextButton fontSize="base" textDecoration="none" data-testid="done-button" onClick={submitModal}>
                      Done
                    </TextButton>
                  </Hide>
                </Actions>
              </Dropdown>
            )}
          </Root>
        </>
      )}
    </ResponsiveModal>
  );
};

OccupantPicker.propTypes = {
  occupants: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
  }),
  labelOptions: PropTypes.object,
  updateQuery: PropTypes.func.isRequired,
  viewThreshold: PropTypes.number,
  verboseInMobile: PropTypes.bool,
  multiroomEnabled: PropTypes.bool,
};

OccupantPicker.defaultProps = {
  occupants: {
    adults: 0,
    children: 0,
    infants: 0,
  },
  labelOptions: {},
  viewThreshold: 0.5,
  verboseInMobile: true,
  multiroomEnabled: true,
};

export default OccupantPicker;
