import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { Wrapper } from '@qga/roo-ui/components';
import { Label, LabelText } from 'components/Label';
import InputWithAddon from 'components/InputWithAddon';
import { useBreakpoints } from 'hooks/useBreakpoints';

const OccupantInput = styled(InputWithAddon)`
  &:focus {
    border-color: ${themeGet('colors.ui.linkFocus')};
  }
`;

const OccupantDisplay = ({ labelOptions, openModal, occupantCount, isOpen, verboseInMobile, ...rest }) => {
  const summaryText = pluralize('Guest', occupantCount, true);
  const { isLessThanBreakpoint } = useBreakpoints();
  const isMobileOrTablet = isLessThanBreakpoint(1);

  return (
    <Wrapper {...rest}>
      {!isMobileOrTablet && (
        <Label position="relative">
          <LabelText {...labelOptions} color="greys.charcoal" fontWeight="normal" fontSize="sm">
            Guests
          </LabelText>
          <InputWithAddon
            data-testid="occupant-picker-input"
            borderRadius="default"
            icon="person"
            value={summaryText}
            onFocus={openModal}
            isHighlighted={isOpen}
            border={2}
            cursor="pointer"
            readonly
          />
        </Label>
      )}

      {isMobileOrTablet && (
        <Label>
          <LabelText {...labelOptions} hidden color="greys.charcoal" fontWeight="normal">
            Guests
          </LabelText>
          <OccupantInput
            data-testid="occupant-picker-input"
            borderRadius="default"
            icon="person"
            value={verboseInMobile ? summaryText : occupantCount}
            onClick={openModal}
            onKeyUp={({ keyCode }) => {
              if (keyCode === 13) openModal();
            }}
            isHighlighted={isOpen}
            readOnly
            border={2}
            cursor="pointer"
            readonly
          />
        </Label>
      )}
    </Wrapper>
  );
};

OccupantDisplay.propTypes = {
  labelOptions: PropTypes.object,
  verboseInMobile: PropTypes.bool,
  openModal: PropTypes.func,
  occupantCount: PropTypes.number,
  isOpen: PropTypes.bool,
  isLabelDisplayed: PropTypes.bool,
};

OccupantDisplay.defaultProps = {
  labelOptions: {},
  verboseInMobile: true,
  openModal: () => {},
  occupantCount: 0,
  isOpen: false,
  isLabelDisplayed: true,
};

export default OccupantDisplay;
